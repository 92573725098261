<template>
  <v-menu
    v-model="showMenu"
    :absolute="absolute"
    :attach="isAttached"
    :close-on-content-click="true"
    :position-x="positionX"
    :position-y="positionY"
    :transition="false"
    nudge-bottom="10"
    content-class="edit-menu"
    offset-y>
    <template
      v-if="showActivator"
      #activator="{ on }">
      <div
        class="pointer"
        v-on="on">
        <v-icon
          medium
          color="mainGrey"
          class="brightness-icon">
          mdi-dots-vertical
        </v-icon>
      </div>
    </template>
    <v-card class="nav-card">
      <slot />
    </v-card>
  </v-menu>
</template>
<script>
export default {
  name: 'AppKebabActions',
  props: {
    absolute: {
      type: Boolean,
      default: false,
    },
    isAttached: {
      type: Boolean,
      default: false,
    },
    positionX: {
      type: Number,
      default: 0,
    },
    positionY: {
      type: Number,
      default: 0,
    },
    showActivator: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showMenu: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('update:value', val);
      },
    },
  },
  watch: {
    showMenu(val) {
      this.$emit('showMenu', val);
    },
  },
};
</script>
<style scoped>
</style>
