<template>
  <app-kebab-actions
    v-if="item"
    v-bind="$attrs"
    :value="value"
    @showMenu="onShowMenu"
    v-on="$listeners">
    <app-action-btn
      :icon-src="require('@/assets/icons/settings-icon.svg')"
      top
      icon-class="op-1 pointer setting-project-icon"
      @click="showProjectInfoModalAction({ id: item.id, role: item.type })">
      Info
    </app-action-btn>
    <app-action-btn
      v-if="item.status === ACTIVE"
      :access="canWorkWithModifyProject"
      :icon-src="require('@/assets/icons/rename-icon.svg')"
      bottom
      show-tooltip
      @click="canWorkWithModifyProject.allowed && $emit('rename-project', item)">
      Rename
    </app-action-btn>
    <app-action-btn
      v-if="!checkGroupByTags"
      :access="canWorkWithArchiveProjectActions"
      :icon-src="require('@/assets/icons/archive-icon.svg')"
      bottom
      show-tooltip
      @click="canWorkWithArchiveProjectActions.allowed && $emit('change-status', item)">
      {{ item.status === ACTIVE ? 'Archive' : 'Unarchive' }}
    </app-action-btn>
    <app-action-btn
      :access="canWorkWithModifyProject"
      :icon-src="require('@/assets/icons/delete-icon.svg')"
      bottom
      icon-height="17px"
      icon-width="17px"
      show-tooltip
      @click="canWorkWithModifyProject.allowed && $emit('delete-project', item)">
      Delete
    </app-action-btn>
    <app-action-btn
      :access="canWorkWithCreateCopyProject"
      bottom
      show-tooltip
      :color-tooltip="disabledPersonalWs ? 'error' : ''"
      @click="canWorkWithCreateCopyProject.allowed && $emit('copy-project', item)">
      <template #iconWrapper>
        <v-icon
          small
          color="lightBlack">
          mdi-content-copy
        </v-icon>
      </template>
      Copy
    </app-action-btn>
  </app-kebab-actions>
</template>
<script>
import {
  mapGetters, mapMutations, mapState,
} from 'vuex';
import { ACTIVE } from '@/constants';
import AppKebabActions from '@/components/App/AppKebabActions';
export default {
  name: 'TableActions',
  components: {
    AppKebabActions,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ACTIVE,
    };
  },
  computed: {
    ...mapState({
      groupByTags: state => state.Projects.groupByTags,
    }),
    ...mapGetters('UserRoles', ['canModifyProject', 'canCreateCopyProject']),
    ...mapGetters('Workspace', ['disabledPersonalWs']),
    canWorkWithModifyProject() {
      return this.canModifyProject(this.item?.type);
    },
    canWorkWithCreateCopyProject() {
      return this.canCreateCopyProject(this.item?.type);
    },
    canWorkWithArchiveProjectActions() {
      if (this.item.status === ACTIVE && this.item) {
        return this.canWorkWithModifyProject;
      } else {
        // use same validation if user does not have subscription(means that he cant have more than 5 active projects) he cant unarhive project
        return this.canCreateCopyProject(this.item?.type);
      }
    },
    checkGroupByTags() {
      const { groupByTags } = this;
      return Object.values(groupByTags).some(item => item?.groupByTagsTrigger);
    },
  },
  methods: {
    ...mapMutations({
      showProjectInfoModalAction: 'showProjectInfoModalAction',
    }),
    async onShowMenu(val) {
      this.menu = val;
      this.$emit('update:value', val);
      if (!val) {
        this.$emit('update:item', null);
      }
    },
  },
};
</script>
<style scoped>
</style>
