<template>
  <div class="projects-list-skeleton px-8 mx-auto">
    <v-skeleton-loader
      class="d-flex justify-space-between flex-column flex-sm-row flex-wrap
             w-100
             projects-list-skeleton-header"
      type="heading@2" />
    <v-skeleton-loader
      class="my-9"
      type="list-item-two-line@3" />
  </div>
</template>

<script>
export default {
  name: 'ProjectsListSkeleton',
};
</script>

<style scoped lang="scss">
  ::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
    .v-skeleton-loader__heading {
      width: 200px;
      height: 28px;
      align-self: flex-end;
      &:first-of-type {
        width: 320px;
        align-self: flex-start;
      }
    }
  }
  ::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
    .v-skeleton-loader__list-item-two-line {
      width: 60%;
    }
  }
  .projects-list-skeleton {
    max-width: 1250px;
    width: 100%;
  }
  .projects-list-skeleton-header {
    gap: 1.5rem;
  }
</style>