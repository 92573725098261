<template>
  <div
    v-if="activeTagName"
    class="active-staring-filter d-flex justify-space-between align-center mx-7 my-3">
    <div>
      <span> Project filtered by</span>
      <v-chip
        class="mx-1"
        color="lightBlack"
        outlined
        label
        x-small>
        {{ activeTagName }}
      </v-chip>
      <span>tag</span>
      <v-icon
        class="icon-staring mx-2"
        @click="actionStarredView">
        {{ iconStar }}
      </v-icon>
      <span>Click the star to create a saved view</span>
    </div>
    <p
      v-if="activeStaringFilteredViewId"
      class="ma-0 mainRed--text"
      @click="setChangeActiveTabView(null)">
      Clear Filter
    </p>
  </div>
</template>
<script>
import ProjectFilterTagsAPI from '@/services/graphql/projectFilterTags';
import {
  mapActions,
  mapGetters, mapMutations, mapState,
} from 'vuex';
export default {
  name: 'ActiveStaringFilterTagPanel',
  props: {
    activeTagName: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState({
      activeWorkspaceId: state => state.Workspace.activeWorkspaceId,
      staringFilteredViews: state => state.Projects.staringFilteredViews,
      activeStaringFilteredViewId: state => state.Projects.activeStaringFilteredViewId,
    }),
    ...mapGetters({
      getSortedItem: 'Projects/getSortedItem',
    }),
    getSortedMode() {
      return this.getSortedItem.sortingMode;
    },
    iconStar() {
      return this.isStaringFilterTag ? 'mdi-star' : 'mdi-star-outline';
    },
    isStaringFilterTag() {
      return this.activeStaringFilteredViewId;
    },
  },
  methods: {
    ...mapMutations({
      spinner: 'spinner',
      setActiveStaringFilteredViewId: 'Projects/setActiveStaringFilteredViewId',
      setStaringFilteredViews: 'Projects/setStaringFilteredViews',
      setChangeActiveTabView: 'Projects/setChangeActiveTabView',
    }),
    ...mapActions({
      handleError: 'handleError',
    }),
    actionStarredView() {
      this.isStaringFilterTag ? this.removedProjectListStarred() : this.setProjectListStarred();
    },
    async removedProjectListStarred() {
      try {
        this.spinner(true);
        const {
          activeWorkspaceId: workspaceId,
          activeStaringFilteredViewId: starredListId,
        } = this;
        const { data } = await ProjectFilterTagsAPI.deleteStarredProjectList({
          workspaceId,
          starredListId,
        });
        const { id = '' } = data.response || {
        };
        if (id) {
          const filterStaringViews = this.staringFilteredViews.filter(e => e.id !== id);
          this.setActiveStaringFilteredViewId('');
          this.setChangeActiveTabView({
            isDisabledWatch: true,
          });
          this.setStaringFilteredViews(filterStaringViews);
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    async setProjectListStarred() {
      try {
        if (this.isStaringFilterTag) return false;
        this.spinner(true);
        const {
          activeWorkspaceId: workspaceId,
          activeTagName: tags,
          getSortedMode: sortingMode,
        } = this;
        const { data } = await ProjectFilterTagsAPI.makeProjectListStarred({
          workspaceId,
          tags,
          sortingMode,
        });
        this.setActiveStaringFilteredViewId(data.response.id);
        this.setStaringFilteredViews([...this.staringFilteredViews, data.response]);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.active-staring-filter {
  .v-btn {
    height: unset !important;
  }
}
</style>
