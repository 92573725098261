<template>
  <v-col
    class="empty-project-wrapper">
    <template v-if="!networkError">
      <h2
        class="empty-project-wrapper__welcome-text">
        {{ getWelcomeText }}
      </h2>
      <img
        class="empty-project-wrapper__background-img"
        src="@/assets/icons/emptyProject.png"
        alt="emptyProject">
      <h1 class="mt-sm-10 mt-3 text-center">
        You don't have any projects yet
      </h1>
      <h2 class="mt-3 text-center">
        {{
          canCreateProject.allowed ?
            "Let's start with your first project" :
            'Ask for access, or switch to an account with a premium access'
        }}
      </h2>
      <app-btn
        :access="canCreateProject"
        btn-class="mt-sm-12 mt-8"
        show-tooltip
        bottom
        style="box-shadow: none"
        color="lightBlue"
        data-test="create_project_button"
        @click="canCreateProject.allowed && $store.commit('showModalAction', 'showCreateProjectModal')">
        CREATE PROJECT
      </app-btn>
    </template>
    <h1
      v-else
      class="mt-10 text-center">
      Your projects are loading, please wait
    </h1>
  </v-col>
</template>
<script>
import {
  mapGetters, mapState,
} from 'vuex';
export default {
  name: 'EmptyListing',
  computed: {
    ...mapGetters({
      getActiveWorkspaceName: 'Workspace/getActiveWorkspaceName',
      canCreateProject: 'UserRoles/canCreateProject',
      getName: 'UserProfile/getName',
    }),
    ...mapState(['networkError']),
    getWelcomeText() {
      const { getActiveWorkspaceName: ws, getName } = this;
      return `Welcome ${getName} to ${ws}!`;
    },
  },
};
</script>
<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';
.empty-project-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  &__welcome-text {
    font-size: 24px;
    margin-bottom: 32px;
    color: grey;
  }
  &__background-img {
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      max-width: 80%;
      height: auto;
    }
  }
  @media (max-height: 649px) {
    top: 60%;
  }
  @media (max-height: 599px) {
    top: 70%;
  }
}
</style>
