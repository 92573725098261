<template>
  <v-tooltip
    v-if="member"
    :disabled="disabledTooltip"
    bottom>
    <template #activator="{ on, attrs }">
      <span
        color="primary"
        dark
        v-bind="attrs"
        v-on="on">
        <AvatarImage
          :avatar="avatars[member.picture]"
          :avatar-key="member.picture"
          :color="color"
          :hide-avatar="hideAvatar"
          style="border-radius: 50%;"
          :size="30"
          position="center" />
      </span>
    </template>
    <span>
      {{ member.name }}
    </span>
  </v-tooltip>
</template>
<script>
import {
  mapState, mapActions,
} from 'vuex';
export default {
  name: 'AppTableMember',
  props: {
    colorIcon: {
      type: String,
      default: '',
    },
    disabledTooltip: {
      type: Boolean,
      default: false,
    },
    hideAvatar: {
      type: Boolean,
      default: false,
    },
    member: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapState(['avatars']),
    color() {
      const { colorIcon, member } = this;
      return colorIcon ? `hsl(${parseInt(colorIcon, 16)}, 100%, 40%)` : member.id ? `#${member.id.substring(0, 6)}` : 'black' ;
    },
  },
  created() {
    this.getAvatar({
      key: this.member?.picture,
      getAvatars: true,
      context: this,
    });
  },
  methods: {
    ...mapActions(['getAvatar']),
  },
};
</script>

