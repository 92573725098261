<template>
  <div>
    <div
      class="d-flex flex-column search-input mt-md-8"
      :style="{
        height: !isProjectsList? 'calc(100% - 205px)' : 'auto'
      }">
      <v-col
        v-if="isProjectsList && !networkError"
        class="page-container">
        <img
          v-if="$vuetify.breakpoint.mdAndUp"
          src="@/assets/icons/projectsBack.png"
          alt="projectsBack"
          class="background-project">
        <v-row no-gutters>
          <div
            class="welcom-block col-sm-7 col-md-8 col-12">
            <span>{{ identificationWS }}</span>
          </div>
          <app-sort
            :default-item="getSortedItem"
            :sort-items="PROJECT_SORT_ITEMS"
            @resort="resortProjects" />
        </v-row>
        <ProjectsTable
          :headers="currentHeaders"
          :data="sortedActiveProjects"
          :status="ACTIVE"
          @deleteProject="onProjectDelete({
            id: $event, status: ACTIVE
          })"
          @updateProject="updateProject"
          @updateStatus="updateStatus" />
        <ProjectsTableArchived
          :data="sortedArchivedProjects"
          :headers="archiveHeaders"
          @updateStatus="updateStatus"
          @deleteProject="onProjectDelete({
            id: $event, status: ARCHIVED
          })" />
        <AppAddButton
          :items="pageActions" />
      </v-col>
      <ProjectsListSkeleton
        v-else-if="showSkeleton" />
      <ProjectsEmptyListing
        v-else-if="showEmptyProjectsBlock" />
    </div>
  </div>
</template>
<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import ProjectsTable from '@/components/Projects/ProjectsTable';
import ProjectsEmptyListing from '@/components/Projects/ProjectsEmptyListing';
import ProjectsListSkeleton from '@/components/Projects/ProjectsListSkeleton';
import AppAddButton from '@/components/App/AppAddButton';
import projectAddIconGrey from '@/assets/icons/projects/prject-icon-grey.svg';
import {
  PERSONAL_WORKSPACE_ID, ARCHIVED, ACTIVE,
} from '@/constants';
import AppSort from '@/components/App/AppSort';
import ProjectsTableArchived from '@/components/Projects/ProjectsTable/ProjectsTableArchived';
import { PROJECT_SORT_ITEMS } from '@/constants/sortable';
import RequestAbortController from '@/utils/RequestAbortController';
export default {
  name: 'ProjectsListV2',
  components: {
    ProjectsTable,
    ProjectsEmptyListing,
    ProjectsListSkeleton,
    AppAddButton,
    AppSort,
    ProjectsTableArchived,
  },
  data: function () {
    return {
      PROJECT_SORT_ITEMS,
      ACTIVE,
      ARCHIVED,
      headers: [
        {
          text: 'Tags', align: 'left', sortable: false, value: 'tags',
        },
        {
          text: 'Members', align: 'left', sortable: false, value: 'projectMembers',
        },
        {
          text: 'Creator', align: 'center', sortable: false, value: 'creator',
        },
        {
          text: '', value: 'action', sortable: false, align: 'right',
        },
      ],
      current: [
        {
          text: 'current', align: 'left', sortable: false, value: 'projectName',
        },
      ],
      archive: [
        {
          text: 'archive', align: 'left', sortable: false, value: 'projectName',
        },
      ],
      abortController: new RequestAbortController(),
    };
  },
  computed: {
    ...mapState(['showSpinner', 'totalSubscription', 'networkError']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters('Workspace', ['getActiveWorkspace', 'disabledPersonalWs']),
    ...mapGetters('UserRoles', ['canCreateProject']),
    ...mapGetters('UserProfile', ['getName']),
    ...mapGetters('Projects', ['getSortedItem', 'sortedActiveProjects', 'sortedArchivedProjects']),
    ...mapState('Projects', ['active', 'archived', 'isFinishedGetOfProjects', 'groupByTags']),
    identificationWS() {
      const { id = '', name = '' } = this.getActiveWorkspace ?? {
      };
      if (id !== PERSONAL_WORKSPACE_ID) {
        return `Welcome ${this.getName} to ${name}!`;
      }
      return `Welcome, ${this.getName}!`;
    },
    showEmptyProjectsBlock() {
      const { isProjectsList, showSpinner, isFinishedGetOfProjects, networkError } = this;
      return !isProjectsList && !showSpinner && isFinishedGetOfProjects || networkError;
    },
    showSkeleton() {
      return this.isFetchingProjectsList;
    },
    isProjectsList() {
      const isActiveSortTrigger = this.groupByTags?.active?.groupByTagsTrigger;
      const isArchivedSortTrigger = this.groupByTags?.archived?.groupByTagsTrigger;
      return isActiveSortTrigger || isArchivedSortTrigger || this.active?.length || this.archived?.length;
    },
    currentHeaders() {
      return this.current.concat(this.headers);
    },
    archiveHeaders() {
      return this.archive.concat(this.headers);
    },
    pageActions() {
      return [
        {
          title: 'Create New Project',
          action: this.onCreateNewProject,
          iconSrc: projectAddIconGrey,
          colorTooltip: this.disabledPersonalWs ? 'error' : '',
          disabled: !this.canCreateProject.allowed,
          access: this.canCreateProject,
        },
      ];
    },
    isFetchingProjectsList() {
      return this.$store.state.Projects.isFetching.projectsList;
    },
  },
  watch: {
    groupByTags: {
      async handler(val) {
        try {
          const { [ACTIVE]: { isDisabledWatch, tag } } = val || {
          };
          if (isDisabledWatch) return;
          if (tag) return;
          this.spinner(true);
          const { sortingMode: orderBy } = this.getSortedItem;
          this.abortController = new RequestAbortController();
          await this.getProjects({
            status: ACTIVE,
            scanIndexForward: false,
            orderBy,
            abortController: this.abortController,
          });
        } catch (e) {
          console.log(e);
        }
      },
      deep: true,
    },
  },
  created() {
    this.abortController = new RequestAbortController();
    this.getData({
      abortController: this.abortController,
    });
    this.subscribeCommentsAmount('subscribeTotalUnreadComments');
  },
  beforeDestroy() {
    this.totalSubscription.unsubscribe();
    this.clearData();
    this.cancelProjectFetch();
  },
  methods: {
    ...mapActions('Comments', ['subscribeCommentsAmount']),
    ...mapActions('Projects', [
      'getProjects',
      'getData',
      'resortProjects',
      'clearData',
      'updateStatus',
    ]),
    ...mapMutations(['spinner']),
    ...mapMutations({
      setProjectsList: 'Projects/setProjectsList',
    }),
    onProjectDelete({ id, status }) {
      const newArr = this[status].filter(item => item.id !== id);
      this.setProjectsList({
        status,
        projects: newArr,
      });
    },
    onCreateNewProject() {
      this.$store.commit('showModalAction', 'showCreateProjectModal');
    },
    updateProject(data) {
      const { status, id } = data;
      const projects = this[status].map(item => {
        return item.id === id ? data : item;
      });
      this.setProjectsList({
        status,
        projects,
      });
    },
    cancelProjectFetch() {
      this.abortController.abort();
    },
  },
};
</script>
<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';
.search-input {
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    height: 100% !important;
  }
}
::v-deep td {
  height: 63px !important;
}
.page-container {
  position: relative;
  .table-container {
    background-color: #fff;
    z-index: 2;
    position: relative;
  }
  .background-project {
    position: absolute;
    z-index: 1;
    z-index: 0;
    right: -132px;
    top: -35px;
    width: 500px;
  }
}
::v-deep .v-input__slot {
  max-height: 30px !important;
  min-height: 30px !important;
}
::v-deep .v-input__append-inner {
  margin-top: 4px !important;
}
</style>
