<template>
  <v-menu
    v-model="editTagsModal"
    offset-y
    z-index="10"
    content-class="edit-tags"
    :close-on-content-click="false">
    <template #activator="{ on }">
      <p
        data-test="add_tags_button"
        class="small-p"
        v-on="on"
        @click="$emit('editTags')">
        <v-icon
          size="15"
          class="mr-1 ml-1 icon-wrapper">
          mdi-plus
        </v-icon>
      </p>
    </template>
    <v-card>
      <v-icon
        medium
        color="lightBlack"
        class="mb-2">
        mdi-tag-outline
      </v-icon>
      <div>
        <v-chip
          v-for="(tag, index) in copiedProjectTags"
          :key="index"
          color="lightBlack"
          label
          close
          outlined
          close-icon="mdi-close"
          x-small
          class="mr-2 mb-2"
          @click:close="manageTags(tag, 'detachProjectTag')">
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="tag-wrapper"
                v-on="on">
                {{ tag }}
              </span>
            </template>
            <span>
              {{ tag }}
            </span>
          </v-tooltip>
        </v-chip>
      </div>
      <v-text-field
        v-model="enteredTag"
        placeholder="Tag"
        single-line
        filled
        dense
        autocomplete="off"
        hide-details
        class="mt-3"
        @keyup.enter="manageTags(enteredTag, 'attachProjectTag')" />
      <p
        v-if="enteredTag"
        class="x-small-p mt-1 mb-0">
        Press ENTER to add "{{ enteredTag }}"
      </p>
      <div
        data-test="tag_block"
        class="mt-2">
        <v-checkbox
          v-for="item in diffWithAllTags"
          :key="item"
          class="mt-0"
          hide-details
          color="lightBlue"
          :ripple="false"
          @change="manageTags(item, 'attachProjectTag')">
          <template #label>
            <span
              data-test="tag_name"
              class="checkbox__label">{{ item }}</span>
          </template>
        </v-checkbox>
      </div>
    </v-card>
  </v-menu>
</template>
<script>
import ProjectTagsApi from '@/services/graphql/projectTags';
import {
  mapMutations,
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';
export default {
  name: 'EditTagsContainer',
  props: {
    projectTags: {
      type: Array,
      default: () => [],
    },
    projectId: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    enteredTag: '',
    selectedTags: [],
    editTagsModal: false,
    copiedProjectTags: [],
  }),
  computed: {
    ...mapGetters('ProjectDetailsTableSchedule', ['allTags']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    diffWithAllTags() {
      return this.lodash.differenceWith(this.allTags,
        this.copiedProjectTags, this.lodash.isEqual);
    },
  },
  watch: {
    editTagsModal(val) {
      if (val) {
        this.getProjectTags();
      } else {
        this.copiedProjectTags = [];
      }
    },
    projectTags: {
      handler(val) {
        this.copiedProjectTags = [...val];
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(['spinner']),
    ...mapActions(['handleError']),
    ...mapActions('ProjectDetailsTableSchedule', ['getAllProjectTags']),
    async getProjectTags() {
      this.spinner(true);
      await this.getAllProjectTags();
      this.spinner(false);
    },
    async manageTags(tagName, criteria) {
      if (!tagName) {
        return;
      }
      this.spinner(true);
      const data = {
        tagName,
        projectId: this.projectId,
        workspaceId: this.activeWorkspaceId,
      };
      try {
        await ProjectTagsApi.manageTags(data, criteria);
        if (criteria === 'detachProjectTag') {
          this.copiedProjectTags.splice(this.copiedProjectTags.indexOf(tagName), 1);
          this.$emit('changeProjectTags', this.copiedProjectTags);
        } else {
          this.enteredTag = '';
          this.copiedProjectTags.push(tagName);
          this.$emit('changeProjectTags', this.copiedProjectTags);
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.checkbox__label {
  width: 100%;
  word-break: break-all;
}
.v-menu__content.edit-tags {
  overflow: hidden;
  .v-card {
    padding: 16px;
    width: 320px;
    height: 310px;
    overflow: auto;
  }
  .tag-wrapper {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.icon-wrapper {
  background: #C1CDCF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 50px;
  font-size: 20px;
  align-self: center;
  color: #fff;
}
p {
  order: 2;
}
</style>
