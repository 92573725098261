<template>
  <div
    :class="getContainerClass"
    @click.stop>
    <template>
      <div class="text-center">
        <v-menu
          content-class="add-btn-menu"
          open-on-hover
          :nudge-left="type == 'footer' ? 133 : 136"
          top
          offset-y>
          <template v-slot:activator="{ on }">
            <div
              :class="getBtnClass"
              v-on="on">
              <v-icon
                :color="type == 'footer' ? 'white' : ''"
                :size="type == 'footer' ? 46 : 30">
                mdi-plus
              </v-icon>
            </div>
          </template>
          <div
            class="list-items">
            <div
              v-for="(item, i) in renderingItemsWithDataTest"
              :key="i"
              :data-test="getTestAtt(item)">
              <app-btn
                :data-test="item.dataTest"
                :color-tooltip="item.colorTooltip"
                top
                :disabled="item.disabled"
                :access="item.access"
                color="#f5f5f500"
                btn-class="list-items__item"
                show-tooltip
                @click="item.action">
                <div class="title-wrapper">
                  <div class="title">
                    {{ item.title }}
                  </div>
                </div>
                <div class="icon-wrapper">
                  <div class="icon">
                    <img :src="item.iconSrc">
                  </div>
                </div>
              </app-btn>
            </div>
          </div>
        </v-menu>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import renderingItemsWithDataTest from '@/utils/renderingItemsWithDataTest';
export default {
  name: 'AppAddButton',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'footer',
      validator(value) {
        return ['footer', 'basic'].includes(value);
      },
    },
  },
  data: () => ({
    upHere: false,
  }),
  computed: {
    ...mapGetters('FeatureFlags', ['showAIChat']),
    renderingItemsWithDataTest() {
      return renderingItemsWithDataTest({
        items: this.items, arg: 'title', after: '_button',
      });
    },
    getContainerClass() {
      if (this.type == 'footer') {
        if (this.showAIChat) {
          return 'add-icon-container z-index-min secondary-space';
        }
        return 'add-icon-container z-index-min';
      }
      return 'add-icon-container__relative';
    },
    getBtnClass() {
      if (this.type == 'footer') {
        return 'add-icon-wrapper__right';
      }
      return 'add-icon-wrapper';
    },
  },
  methods: {
    getTestAtt({ testAttr = null } = {
    }) {
      return testAttr;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
.add-icon-container {
  position: fixed;
  right: 44px;
  bottom: 34px;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    bottom: 88px;
    right: 6px;
  }

  &.secondary-space {
    right: 104px;
    bottom: 34px;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      bottom: 88px;
      right: 66px;
    }
  }
}
.z-index-max {
  z-index: 9999;
}
.z-index-min {
  z-index: 99;
}
.add-icon-container:hover {
  .add-icon-wrapper {
    background: linear-gradient(270deg, #71D6E0 -42.03%, #2361BE 134.48%);
  }
}
.add-icon-wrapper {
  cursor: pointer;
  border-radius: 50px;
  transform: matrix(1, 0, 0, -1, 0, 0);
  &__right {
    float: right;
    cursor: pointer;
    background: linear-gradient(270deg, #71D6E0 -42.03%, #0AA8DA 134.48%);
    border-radius: 50px;
    transform: matrix(1, 0, 0, -1, 0, 0);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  }
}
::v-deep .list-items {
  // height: 32px;
  display: grid;
  justify-items: end;
  margin-bottom: 10px;
  padding-right: 4px;
  gap: 5px;
  &__item:before {
    background-color: #f5f5f500;
  }
  &__item {
    padding: 0px !important;
    cursor: pointer !important;
    display: flex;
    justify-content: space-between;
    .title {
      width: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 7px;
      cursor: pointer;
      padding: 3px 8px;
      display: flex;
      background: #ffffff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      font-size: 12px !important;
      line-height: 15px;
      color: #7c8d92;
    }
  }
  &__item:hover {
    .title {
      background: #e4e4e4;
    }
    .icon-wrapper {
      background-color: #D1D1D1;
    }
  }
}
.icon-wrapper {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #E2E2E2;
  border-radius: 50px;
}
.icon {
  display: flex;
}
.title-wrapper {
  margin-left: auto;
}
::v-deep .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
  background-color: rgba(0, 0, 0, 0) !important;
}
.add-btn-menu {
  background-color: initial !important;
  background: initial !important;
  box-shadow: initial;
  .list-items__item:before {
    background-color: initial !important;
  }
  .v-menu__content:focus {
    background-color: initial !important;
  }
}
</style>
