<template>
  <app-dialog
    v-if="data.length"
    v-model="dialog"
    :value.sync="dialog"
    :width="1124"
    persistent
    content-class="v-dialog__form"
    @keydown.esc="dialog = false ">
    <template #activator="{ on, attrs }">
      <div
        class="mt-6 mb-2"
        v-bind="attrs"
        v-on="on">
        <v-icon
          size="50">
          mdi-archive
        </v-icon>
        <div class="font-small">
          Archive
        </div>
      </div>
    </template>
    <v-card-title>
      <div class="d-flex align-center">
        <span>Archive</span>
      </div>
      <v-icon
        color="#fff"
        @click="dialog = false">
        mdi-close
      </v-icon>
    </v-card-title>
    <ProjectsTable
      :headers="headers"
      :data="data"
      :status="ARCHIVED"
      v-on="$listeners" />
  </app-dialog>
</template>
<script>
import ProjectsTable from '@/components/Projects/ProjectsTable';
import { ARCHIVED } from '@/constants';
export default {
  name: 'ProjectsTableArchived',
  components: {
    ProjectsTable,
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,
      ARCHIVED,
    };
  },
  watch: {
    data() {
      if (!this.data.length) {
        this.dialog = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';
.search-input {
  margin: 50px auto;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    height: 100% !important;
  }
}
::v-deep td {
  height: 63px !important;
}
.page-container {
  position: relative;
  .table-container {
    background-color: #fff;
    z-index: 2;
    position: relative;
  }
  .background-project {
    position: absolute;
    z-index: 1;
    z-index: 0;
    right: -132px;
    top: -35px;
    width: 500px;
  }
}
::v-deep .v-input__slot {
  max-height: 30px !important;
  min-height: 30px !important;
}
::v-deep .v-input__append-inner {
  margin-top: 4px !important;
}
</style>
