<template>
  <projects-list />
</template>
<script>
import ProjectsList from '@/components/Projects/ProjectsList';
export default {
  name: 'Projects',
  components: {
    ProjectsList,
  },
};
</script>
