<template>
  <div
    class="filter-by-tabs-container">
    <v-menu
      offset-y>
      <template
        v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
          @click="getAllTags">
          Tags
          <v-icon x-small>
            mdi-filter
          </v-icon>
        </span>
      </template>
      <v-list
        v-if="tags.length">
        <v-list-item
          v-for="(tag, index) in tags"
          :key="index"
          :class="{'v-item--active v-list-item--active': tag == getActiveTagByStatus }">
          <v-list-item-title
            @click="changeTags(tag, true)">
            {{ tag }}
          </v-list-item-title>
        </v-list-item>
        <div
          class="tags-actions">
          <v-btn
            :disabled="!groupByTags[status].tag"
            color="lightBlue"
            @click="changeTags('', false)">
            Remove Filter
          </v-btn>
        </div>
      </v-list>
      <div
        v-else
        class="tags-actions">
        <span>No tags available</span>
      </div>
    </v-menu>
  </div>
</template>
<script>
import {
  mapMutations,
  mapActions,
  mapState,
} from 'vuex';
export default {
  name: 'FilterByTagsContainer',
  props: {
    status: {
      type: String,
      default: 'active',
    },
    tags: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('Projects', ['changeActiveTabView', 'groupByTags']),
    getActiveTagByStatus() {
      return this.groupByTags[this.status]?.tag;
    },
  },
  watch: {
    changeActiveTabView: {
      deep: true,
      handler(val) {
        if (val?.isDisabledWatch) return false;
        const tag = !val ? '' : val.tags?.[0];
        const groupByTagsTrigger = Boolean(tag);
        this.manageGroupTags(tag, groupByTagsTrigger);
      },
    },
  },
  methods: {
    ...mapActions({
      setActiveViewIdByTags: 'Projects/setActiveViewIdByTags',
    }),
    ...mapMutations({
      setChangeActiveTabView: 'Projects/setChangeActiveTabView',
    }),
    changeTags(tag, groupByTagsTrigger) {
      this.setChangeActiveTabView({
        isDisabledWatch: true,
      });
      this.manageGroupTags(tag, groupByTagsTrigger);
    },
    async getAllTags() {
      this.$emit('getAllTags');
    },
    manageGroupTags(tag, groupByTagsTrigger) {
      this.setActiveViewIdByTags(tag);
      this.$emit('manageGroupTags', {
        tag,
        groupByTagsTrigger,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.v-list-item:hover {
  background-color: rgb(230, 230, 230);
  cursor: pointer;
}
.tags-actions {
  padding: 20px;
}
</style>
