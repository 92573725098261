var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container table-container__shadowed"},[_c('v-data-table',{staticClass:"v-data-table--listing",class:{'active-tag':_vm.activeTagName },attrs:{"headers":_vm.headersForRendering,"items":_vm.data,"item-key":"id","data-test":"project_table","disable-pagination":"","must-sort":"","mobile-breakpoint":0,"hide-default-footer":"","no-data-text":_vm.emptyText,"elevation":0,"search":_vm.searchString,"custom-filter":_vm.customSearch,"no-results-text":"No matching projects found"},on:{"click:row":function($event){return _vm.pushDetailRoute($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [(!_vm.isArchivedProjects)?_c('ActiveStaringFilterTagPanel',{attrs:{"active-tag-name":_vm.activeTagName}}):_vm._e(),(_vm.showSearchBlock)?_c('TableSearching',{attrs:{"search":_vm.searchString},on:{"update:search":function($event){_vm.searchString=$event}}}):_vm._e()]},proxy:true},{key:"item.creator",fn:function(ref){
var item = ref.item;
return [_c('Creator',{attrs:{"member":item.creator}})]}},{key:"item.projectMembers",fn:function(ref){
var item = ref.item;
return [(_vm.isShared(item))?_c('AppMembers',{attrs:{"role":item.type,"members":item.memberSummary.users,"member-count":item.memberSummary.count},on:{"showShareModalAction":function($event){return _vm.showShareModalAction(item)}}}):_vm._e()]}},{key:"item.tags",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-wrap justify-content-start pt-3 pr-0 pb-0",staticStyle:{"width":"100%"}},[_vm._l((item.tags),function(tag,index){return _c('v-chip',{key:index,staticClass:"mr-2 mb-2",attrs:{"color":"lightBlack","label":"","outlined":"","x-small":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"tag-wrapper"},'span',attrs,false),on),[_vm._v(" "+_vm._s(tag)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(tag)+" ")])])],1)}),(_vm.showEditTagsContainer)?_c('EditTagsContainer',{attrs:{"project-tags":_vm.projectTags,"project-id":_vm.projectId},on:{"editTags":function($event){return _vm.editTags(item)},"changeProjectTags":_vm.changeProjectTags}}):_vm._e()],2)]}},{key:"item.projectName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"d-flex",staticStyle:{"position":"relative"}},[_c('IconTemplate',{staticClass:"mr-4",style:({ marginBottom: (!_vm.renameItem || _vm.renameItem && _vm.renameItem.id !== item.id) ? 0 : '2px'}),attrs:{"color":_vm.colorIcon(item.projectColor),"icon-id":_vm.projectIcon(item.projectIcon)}}),_c('CommentBadge',{staticClass:"v-badge__expanded row-badge",attrs:{"item":{
              commentsUnread: item.commentsUnread,
            },"badge-type":_vm.TYPE_ROW_BADGE}})],1),(!_vm.renameItem || _vm.renameItem && _vm.renameItem.id !== item.id)?_c('div',{staticClass:"project-title-wrapper"},[_c('v-row',{staticClass:"ma-0 flex-nowrap",attrs:{"align":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"overflow-dots"},'span',attrs,false),on),[_c('span',{attrs:{"data-test":"project_name"}},[_vm._v(_vm._s(_vm.getProjectTitle(item)))]),_c('p',{staticClass:"mb-0"},[_vm._v("Last modified "+_vm._s(_vm.setAppropriateFormat(item.projectLastModified)))])])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.projectName)+" ")])])],1)],1):(_vm.renameItem && _vm.renameItem.id === item.id)?_c('v-row',{staticClass:"ma-0 flex-nowrap",staticStyle:{"width":"100%"}},[_c('v-text-field',{ref:"editInput",staticClass:"edit-input",attrs:{"value":_vm.editName,"placeholder":"Enter project name","autocomplete":"off"},on:{"input":_vm.edit,"blur":_vm.saveEditedName,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.saveEditedName.apply(null, arguments)}}}),(item.isShared)?_c('img',{staticClass:"ml-3 brightness-icon",attrs:{"src":require("@/assets/icons/share-icon.svg")}}):_vm._e()],1):_vm._e()],1)]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{staticClass:"brightness-icon",attrs:{"medium":"","color":"mainGrey"},on:{"click":function($event){$event.stopPropagation();return _vm.setMenuItem({
          item: item,
          event: $event,
        })}}},[_vm._v(" mdi-dots-vertical ")])]}},{key:"header.projectName",fn:function(){return [_c('span',[_vm._v(" Project Name ")])]},proxy:true},{key:"header.tags",fn:function(){return [(_vm.status == _vm.ACTIVE)?_c('FilterByTagsContainer',{attrs:{"status":_vm.status,"tags":_vm.allTags},on:{"getAllTags":_vm.getAllTags,"manageGroupTags":_vm.manageGroupTags}}):_c('span',[_vm._v("Tags")])]},proxy:true}],null,true)}),_c('table-actions',{attrs:{"show-activator":false,"item":_vm.selectedItem,"position-x":_vm.positionX,"position-y":_vm.positionY,"value":_vm.showActionsCard},on:{"update:item":function($event){_vm.selectedItem=$event},"update:value":function($event){_vm.showActionsCard=$event},"copy-project":_vm.copy,"rename-project":_vm.rename,"delete-project":_vm.deleteProject,"change-status":_vm.changeStatus}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }