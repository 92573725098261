<template>
  <div
    class="app-sort d-flex justify-end justify-sm-space-between flex-shrink-1 flex-wrap flex-sm-nowrap gap-3"
    :class="wrapperClass">
    <router-link
      v-if="$route.name === 'collections'"
      :to="{ name: 'libraries', params: { wId: activeWorkspaceId } }"
      class="d-flex align-start gap-1 fs-18 darkGrey--text text-decoration-underline no-wrap">
      View Libraries
      <i class="nav-svg-icon icon-export lib-icon" />
    </router-link>
    <div
      class="d-flex justify-end">
      <p class="subtitle-1 mb-0">
        Sort by:
      </p>
      <v-select
        v-model="sortedParam"
        data-test="open_sort_by_dropdown"
        hide-details
        height="29"
        class="select-sort"
        :items="sortItems"
        :disabled="disabled"
        return-object
        item-value="request"
        item-text="name"
        :menu-props="{ bottom: true, offsetY: true }"
        dense
        outlined
        @change="$emit('resort', sortedParam)">
        <template #selection="{ item, attrs }">
          <div
            v-bind="attrs"
            class="v-select__selection v-select__selection--comma">
            {{ item.name }}
            <ArrowIcon
              :arrow-icon="item.arrowIcon"
              color="lightBlack" />
          </div>
        </template>
        <template #item="{ item, on }">
          <v-list-item
            :data-test="item.dataTest"
            :class="{'v-item--active v-list-item--active': item.request === sortedParam.request }"
            v-on="on">
            <v-list-item-title>
              {{ item.name }}
              <ArrowIcon :arrow-icon="item.arrowIcon" />
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-select>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  name: 'AppSort',
  components: {
    ArrowIcon: () => import('./AppSortArrow'),
  },
  props: {
    sortItems: {
      type: Array,
      default: () => [],
      required: true,
    },
    defaultItem: {
      type: Object,
      default: null,
    },
    wrapperClass: {
      type: String,
      default: 'col-sm-5 col-md-4 col-12',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selectWidth: {
      type: String,
      default: '205px',
    },
  },
  data() {
    return {
      sortedParam: '',
    };
  },
  computed: {
    ...mapState('Workspace', ['activeWorkspaceId']),
  },
  created() {
    this.sortedParam = this.defaultItem || this.sortItems[0];
  },
};
</script>
<style lang="scss" scoped>
.app-sort {
  .lib-icon {
    display: block;
    width: 16px;
  }

  .wrapper {
    justify-content: flex-end;
    align-items: center;
    display: flex;
  }
  .select-sort {
    margin-left: 10px;
    flex: 0 0 205px;
    border-radius: 4px !important;
    ::v-deep .v-input__control {
      .v-input__slot {
        margin-left: auto;
        min-height: 29px !important;
        .v-select__slot .v-input__append-inner {
          margin-top: 0;
          align-self: center;
        }
      }
    }
  }
}
</style>
