<template>
  <div
    class="table-container table-container__shadowed">
    <v-data-table
      class="v-data-table--listing"
      :class="{'active-tag':activeTagName }"
      :headers="headersForRendering"
      :items="data"
      item-key="id"
      data-test="project_table"
      disable-pagination
      must-sort
      :mobile-breakpoint="0"
      hide-default-footer
      :no-data-text="emptyText"
      :elevation="0"
      :search="searchString"
      :custom-filter="customSearch"
      no-results-text="No matching projects found"
      @click:row="pushDetailRoute($event)">
      <template
        #top>
        <ActiveStaringFilterTagPanel
          v-if="!isArchivedProjects"
          :active-tag-name="activeTagName" />
        <TableSearching
          v-if="showSearchBlock"
          :search.sync="searchString" />
      </template>
      <template #[`item.creator`]="{ item }">
        <Creator :member="item.creator" />
      </template>
      <template #[`item.projectMembers`]="{ item }">
        <AppMembers
          v-if="isShared(item)"
          :role="item.type"
          :members="item.memberSummary.users"
          :member-count="item.memberSummary.count"
          @showShareModalAction="showShareModalAction(item)" />
      </template>
      <template #[`item.tags`]="{ item }">
        <div
          class="d-flex flex-wrap justify-content-start pt-3 pr-0 pb-0"
          style="width: 100%">
          <v-chip
            v-for="(tag, index) in item.tags"
            :key="index"
            color="lightBlack"
            label
            outlined
            x-small
            class="mr-2 mb-2">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  class="tag-wrapper"
                  v-on="on">
                  {{ tag }}
                </span>
              </template>
              <span>
                {{ tag }}
              </span>
            </v-tooltip>
          </v-chip>
          <EditTagsContainer
            v-if="showEditTagsContainer"
            :project-tags="projectTags"
            :project-id="projectId"
            @editTags="editTags(item)"
            @changeProjectTags="changeProjectTags" />
        </div>
      </template>
      <template #[`item.projectName`]="{ item }">
        <div
          style="display: flex; align-items: center">
          <div
            class="d-flex"
            style="position: relative;">
            <IconTemplate
              class="mr-4"
              :color="colorIcon(item.projectColor)"
              :icon-id="projectIcon(item.projectIcon)"
              :style="{ marginBottom: (!renameItem || renameItem && renameItem.id !== item.id) ? 0 : '2px'}" />
            <CommentBadge
              class="v-badge__expanded row-badge"
              :item="{
                commentsUnread: item.commentsUnread,
              }"
              :badge-type="TYPE_ROW_BADGE" />
          </div>
          <div
            v-if="!renameItem || renameItem && renameItem.id !== item.id"
            class="project-title-wrapper">
            <v-row
              class="ma-0 flex-nowrap"
              align="center">
              <v-tooltip
                top>
                <template #activator="{ on, attrs }">
                  <span
                    class="overflow-dots"
                    v-bind="attrs"
                    v-on="on">
                    <span data-test="project_name">{{ getProjectTitle(item) }}</span>
                    <p class="mb-0">Last modified {{
                      setAppropriateFormat(item.projectLastModified)
                    }}</p>
                  </span>
                </template>
                <span>
                  {{ item.projectName }}
                </span>
              </v-tooltip>
            </v-row>
          </div>
          <v-row
            v-else-if="renameItem && renameItem.id === item.id"
            style="width: 100%"
            class="ma-0 flex-nowrap">
            <v-text-field
              ref="editInput"
              :value="editName"
              placeholder="Enter project name"
              class="edit-input"
              autocomplete="off"
              @input="edit"
              @blur="saveEditedName"
              @keyup.enter="saveEditedName" />
            <img
              v-if="item.isShared"
              class="ml-3 brightness-icon"
              src="@/assets/icons/share-icon.svg">
          </v-row>
        </div>
      </template>
      <template #[`item.action`]="{ item }">
        <v-icon
          medium
          color="mainGrey"
          class="brightness-icon"
          @click.stop="setMenuItem({
            item,
            event: $event,
          })">
          mdi-dots-vertical
        </v-icon>
      </template>
      <template #[`header.projectName`]>
        <span>
          Project Name
        </span>
      </template>
      <template #[`header.tags`]>
        <FilterByTagsContainer
          v-if="status == ACTIVE"
          :status="status"
          :tags="allTags"
          @getAllTags="getAllTags"
          @manageGroupTags="manageGroupTags" />
        <span v-else>Tags</span>
      </template>
    </v-data-table>
    <table-actions
      :show-activator="false"
      :item.sync="selectedItem"
      :position-x="positionX"
      :position-y="positionY"
      :value.sync="showActionsCard"
      @copy-project="copy"
      @rename-project="rename"
      @delete-project="deleteProject"
      @change-status="changeStatus" />
  </div>
</template>
<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import ProjectsApi from '@/services/graphql/projects';
import IconTemplate
  from '@/components/ProjectDetails/ProjectDetailsSetColoredIcon/ProjectDetailsSetColoredIconTemplate';
import EditTagsContainer from './EditTagsContainer';
import FilterByTagsContainer from './FilterByTagsContainer';
import AppMembers from '@/components/App/AppTable/AppTableMembers';
import Creator from '@/components/App/AppTable/AppTableMember';
import CommentBadge from '@/components/ProjectDetails/ProjectDetailsCommentBadge';
import TableHeadersToMobile from '@/mixins/TableHeadersToMobile';
import AppActionDotsMenu from '@/mixins/AppActionDotsMenu';
import TableActions from '@/components/Projects/ProjectsTable/ProjectsTableStructure/TableActions';
import {
  ACTIVE, TYPE_ROW_BADGE, ARCHIVED,
} from '@/constants';
import ActiveStaringFilterTagPanel
  from '@/components/Projects/ProjectsTable/ProjectsTableStructure/ActiveStaringFilterTagPanel';
export default {
  name: 'ProjectsTableStructure',
  components: {
    ActiveStaringFilterTagPanel,
    EditTagsContainer,
    IconTemplate,
    AppMembers,
    Creator,
    FilterByTagsContainer,
    CommentBadge,
    TableSearching: () => import('./ProjectsTableStructureSearching'),
    TableActions,
  },
  mixins: [TableHeadersToMobile, AppActionDotsMenu],
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    status: {
      type: String,
      default: ACTIVE,
    },
    emptyText: {
      type: String,
      default: 'There are no projects',
    },
  },
  data: () => ({
    ACTIVE,
    TYPE_ROW_BADGE,
    showProjecListAction: false,
    renameItem: null,
    editName: null,
    projectTags: [],
    projectId: null,
    // for TableHeadersToMobile mixin
    MOBILE_COLS_TO_RENDERING: [
      'projectName',
      'action',
    ],
    searchString: '',
  }),
  computed: {
    ...mapState({
      groupByTags: state => state.Projects.groupByTags,
      activeWorkspaceId: state => state.Workspace.activeWorkspaceId,
    }),
    ...mapGetters('Workspace', ['disabledPersonalWs']),
    ...mapGetters(['premiumGroup', 'collaboratorGroup']),
    ...mapGetters('UserRoles', ['canModifyProject', 'canCreateCopyProject']),
    ...mapGetters('ProjectDetailsTableSchedule', ['allTags']),
    ...mapGetters('ProjectDetails', ['getProjectTitle']),
    ...mapGetters('Projects', ['getProjectRelatedNextToken']),
    showEditTagsContainer() {
      const { isArchivedProjects } = this;
      if (isArchivedProjects) return false;
      const { groupByTags } = this;
      return !groupByTags[ACTIVE]?.groupByTagsTrigger;
    },
    isArchivedProjects() {
      return this.status === ARCHIVED;
    },
    showSearchBlock() {
      const { getProjectRelatedNextToken, data, status } = this;
      return !getProjectRelatedNextToken(status) && data.length;
    },
    activeTagName() {
      return this.groupByTags.active.tag;
    },
  },
  methods: {
    ...mapMutations({
      setGroupByTags: 'Projects/setGroupByTags',
      showShareModalAction: 'showShareModalAction',
      spinner: 'spinner',
      showProjectInfoModalAction: 'showProjectInfoModalAction',
    }),
    ...mapActions({
      handleError: 'handleError',
      setCreatedProject: 'Projects/setCreatedProject',
      getAllProjectTags: 'ProjectDetailsTableSchedule/getAllProjectTags',
    }),
    customSearch() {
      const [value, search, item] = arguments;
      if (!value || !search) return false;
      const searchBy = ['projectName', 'number'];
      const searchStr = search?.toLowerCase();
      return searchBy.some(s => {
        const itemVal = item?.[s]?.toString()?.toLowerCase();
        if (!itemVal) return false;
        return itemVal.includes(searchStr);
      });
    },
    colorIcon(projectColor) {
      return projectColor?.hex || undefined;
    },
    projectIcon(projectIcon) {
      return projectIcon?.uuid ? `Icon_template_${projectIcon.uuid}` : undefined;
    },
    isShared({ memberSummary }) {
      return memberSummary?.users?.length;
    },
    async copy(item) {
      this.spinner(true);
      try {
        const workspaceId = this.activeWorkspaceId;
        const { data } = await ProjectsApi.copyProject({
          projectId: item.id,
          workspaceId,
        });
        this.setCreatedProject(data.response);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    async getAllTags() {
      this.spinner(true);
      await this.getAllProjectTags();
      this.spinner(false);
    },
    manageGroupTags(tagItems) {
      const { tag, groupByTagsTrigger } = tagItems;
      const payload = {
        groupByTagsTrigger,
        status: this.status,
        tag,
      };
      this.setGroupByTags(payload);
    },
    setAppropriateFormat(data) {
      const compareDateWith24h = this.$moment().diff(this.$moment(data), 'hours');
      return this.$moment(data).format(compareDateWith24h >= 24 ? 'YYYY-MM-DD' : 'HH:mm');
    },
    pushDetailRoute({ id }) {
      if (!this.renameItem) {
        this.$router.push({
          path: `project/${id}`,
        });
      }
    },
    async updateProject(data, criteria) {
      this.spinner(true);
      try {
        const workspaceId = this.activeWorkspaceId;
        const response = await ProjectsApi.updateProject({
          ...data,
          workspaceId,
        });
        const currentItem = this.data.find(item => item.id === data.id);
        const updatedProject = response.data.response;
        if (criteria === 'status') {
          this.$emit('updateStatus', {
            ...currentItem,
            status: updatedProject.status,
          });
        } else {
          this.renameItem = null;
          this.$emit('updateProject', {
            ...currentItem,
            projectName: updatedProject.projectName,
          });
        }
      } catch (err) {
        this.renameItem = null;
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    async deleteProject(item) {
      const response = await this.$openConfirm({
        text: `Are you sure you want to delete project ${item.projectName}???`,
      });
      if (response) {
        this.spinner(true);
        try {
          const workspaceId = this.activeWorkspaceId;
          await ProjectsApi.deleteProject({
            id: item.id,
            workspaceId,
          });
          this.$emit('deleteProject', item.id);
        } catch (err) {
          this.handleError(err);
        } finally {
          this.spinner(false);
        }
      }
    },
    editTags({ tags, id }) {
      this.projectTags = tags || [];
      this.projectId = id;
    },
    changeProjectTags(tags) {
      const project = this.data.find(item => item.id === this.projectId);
      this.$emit('updateProject', {
        ...project,
        tags,
      });
    },
    rename(item) {
      this.renameItem = item;
      this.editName = item.projectName;
      this.$nextTick(() => {
        this.$refs.editInput.focus();
      });
    },
    changeStatus(item) {
      const jsonForRequest = {
        id: item.id,
        projectName: item.projectName,
        status: item.status === ARCHIVED ? ACTIVE : ARCHIVED,
        sourceLibraryId: item.sourceLibraryId,
      };
      this.updateProject(jsonForRequest, 'status');
    },
    edit(e) {
      this.editName = e;
    },
    saveEditedName() {
      if (!this.editName) {
        this.renameItem = null;
      } else {
        const jsonForRequest = {
          id: this.renameItem.id,
          projectName: this.editName,
          status: this.renameItem.status,
        };
        this.updateProject(jsonForRequest, 'name');
      }
    },
  },
};
</script>
<style scoped lang="scss">
.project-title-wrapper {
  width: calc(100% - 40px);
}
.tag-wrapper {
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}
::v-deep .v-avatar img {
  border: 1px solid #FFFFFF !important;
}
::v-deep .v-chip {
  border: 1px solid var(--v-mainGrey-base) !important;
  padding: 2px 5px !important;
  height: 19px !important;
  .v-chip__content {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #72858A;
  }
}
svg {
  width: 37px;
  height: 37px;
}
.v-data-table {
  position: relative;
}
.table-container__shadowed {
  padding: 0 !important;
}
::v-deep .v-data-table__wrapper {
  margin: 35px 28px 50px 28px;
}
</style>
