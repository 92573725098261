<template>
  <div
    :class="`${status}-projects-table`">
    <ProjectsTableStructure
      v-if="isGroupByTagsTrigger || dataLength || getStarringViewIdByActiveTab"
      :headers="headers"
      :data="data"
      :status="status"
      :empty-text="getEmptyText"
      v-on="$listeners" />
  </div>
</template>
<script>
import {
  mapGetters, mapState,
} from 'vuex';
import ProjectsTableStructure from './ProjectsTableStructure';
export default {
  name: 'ProjectsTable',
  components: {
    ProjectsTableStructure,
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    status: {
      type: String,
      default: 'active',
    },
  },
  computed: {
    ...mapState({
      groupByTags: state => state.Projects.groupByTags,
    }),
    ...mapGetters({
      getStarringViewIdByActiveTab: 'Projects/getStarringViewIdByActiveTab',
    }),
    dataLength() {
      return this.data.length;
    },
    getEmptyText() {
      const { dataLength: leng, isGroupByTagsTrigger: isGroup } = this;
      if (leng || !isGroup) return '';
      return 'There are no projects with this tag';
    },
    isGroupByTagsTrigger() {
      const { groupByTagsTrigger = false } = this.groupByTags[this.status] ?? {
      };
      return groupByTagsTrigger;
    },
  },
};
</script>
